@use "../../assets/styles/mixins" as mixins;
@use "../../assets/styles/variables" as vars;

$INPUT-WIDTH: 58.4%;
$INPUT-WIDTH-TABLET: 85%;
$INPUT-WIDTH-MOBILE: calc(100% - 2 * 20px);

.form {
  max-width: 830px;
  min-width: 500px;
  max-height: 924px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  background: vars.$globalBlack;
  align-items: center;

  @media (max-width: vars.$Tablet) {
    font-size: vars.$fs-18;
    min-width: 100%;
  }

  @media (max-width: vars.$Mobile) {
    max-width: 100%;
    max-height: unset;
    border-radius: 0;
    align-items: stretch;
  }
  
  &__container {
    width: $INPUT-WIDTH;
    padding-top: 35px;
    @media (max-width: vars.$Tablet) {
      width: $INPUT-WIDTH-TABLET;
      padding-top: 23px;
    }
    @media (max-width: vars.$Mobile) {
      width: $INPUT-WIDTH-MOBILE;
      padding-top: 34px;
    }
  }

  &__wrapper {
    border: none;
    border-bottom-style: solid;
    border-width: 3px;
    text-transform: uppercase;
    color: vars.$globalWhite;
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 10px;

    @media (max-width: vars.$Tablet) {
      border-width: 2px;
      text-transform: lowercase;
      font-size: vars.$fs-18;
    }

    @media (max-width: vars.$Mobile) {
      padding-left: 31.25px;
      margin-right: 31.25px;
      width: calc(100% - 31.25px);
      margin-top: 38px;
    }

    @media (max-width: vars.$MobileSmall) {
      padding-left: 20px;
      margin-right: 20px;
      width: 100%;
      margin-top: 10px;
    }

    &::after {
      content: "";
      z-index: 2;
      position: absolute;
      right: -9px;
      bottom: -9px;
      width: 15px;
      height: 15px;
      background: vars.$globalWhite;
      border-radius: 50%;

      @media (max-width: vars.$Tablet) {
        right: -7px;
        bottom: -7px;
        width: 10px;
        height: 10px;
      }

      @media (max-width: vars.$Mobile) {
        right: -9px;
        bottom: -9px;
        width: 17px;
        height: 17px;
      }

      @media (max-width: vars.$MobileSmall) {
        right: -7px;
        bottom: -7px;
        width: 10px;
        height: 10px;
      }
    }
  }

  &__inputs {
    @include mixins.fontSet(vars.$secondaryFont, vars.$fs-30, vars.$fw-400);
    background-color: vars.$globalBlack;
    color: vars.$globalWhite;
    width: 100%;
    min-height: 30px;
    padding: 3px 0 0;
    outline: none;

    &::placeholder {
      text-transform: uppercase;
    }

    @media (max-width: vars.$Tablet) {
      font-size: vars.$fs-18;
      padding: 0 0;

      &::placeholder {
        text-transform: lowercase;
      }
    }

    @media (max-width: vars.$Mobile) {
      @include mixins.fontSet(vars.$mainFont, 25px, 400, 30px);
      padding: 3px 0;
    }

    @media (max-width: vars.$MobileSmall) {
      @include mixins.fontSet(vars.$secondaryFont, 18px, 400, 25px);
    }

    &_type_textarea {
      height: auto;
      border: none;
      resize: none;
      padding: 0 0 5px;
      outline: none;

      &::placeholder {
        transform: translateY(5em);
      }
    }
  }

  &__error {
    margin: 0;
    width: 100%;

    color: #f00;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    align-self: flex-start;
    text-align: left;

    opacity: 0;
    transition: opacity 0.3s;

    display: inline-block;

    ////////////////////////////////////////////////////////////////////////////////
    /// Резерв пространства под 1 строку текста ошибки, чтобы уменьшить "пляску" координат элементов и размера формы от валидации
    ////////////////////////////////////////////////////////////////////////////////

    line-height: 1.2em; // равносильно значению normal
    min-height: 1.2em; // пространство под одну строку должно быть зарезервировано

    /* Для резервирования пространства размещения элемента, иначе с нулевой шириной элемент схлопывается и контролы пляшут от валидации */
    min-width: 1px;

    ////////////////////////////////////////////////////////////////////////////////

    /* Чтобы слишком длинный текст ошибки не вылезал за пределы страницы */
    // overflow: auto; // Вроде без этого работает

    &_active {
      opacity: 1;
    }

    @media (max-width: vars.$Tablet) {
      font-size: 14px;
    }

    @media (max-width: vars.$Mobile) {
      margin: 0 20px;
    }
  }

  &__submit {
    @include mixins.fontSet(vars.$mainFont, vars.$fs-30, 500);
    width: 100%;
    height: auto;
    border-radius: 25px;
    border: none;
    background: vars.$globalGold;
    margin-top: 231px;
    white-space: nowrap;
    padding: 29px 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 0;
    transition: 0.5s ease;

    &:hover:not([disabled]) {
      opacity: 0.8;
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;

      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    &_sended {
      background: vars.$globalBlack;
      color: vars.$globalWhite;
      border: 3px vars.$globalWhite solid;
    }

    @media screen and (max-width: vars.$Tablet) {
      width: auto;
      min-width: 283px;
      max-width: $INPUT-WIDTH-TABLET;
      border-radius: 15px;
      font-size: vars.$fs-18;
      margin: 20px auto;
    }

    @media screen and (max-width: vars.$Mobile) {
      font-size: 30px;
      font-weight: 400;

      max-width: $INPUT-WIDTH-MOBILE;
      border-radius: 15px;
      margin: 25px auto 45px;
      padding: 19px 8px;
    }

    @media screen and (max-width: vars.$MobileSmall) {
      font-size: 18px;
      font-weight: 500;

      min-width: 204px;
      max-width: $INPUT-WIDTH-MOBILE;
      border-radius: 10px;
      margin: 1px auto 20px;
      padding: 14px 9px;
    }
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: vars.$globalWhite;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: vars.$globalWhite;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: vars.$globalWhite;
}

input {
  border: none;
}

textarea {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  display: none;
}
