@use "../../../assets/styles/mixins" as mixins;
@use "../../../assets/styles/variables" as vars;

.slider {
  width: 100%;
  position: relative;

  &__img {
    width: 100%;
  }

  &__menu {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 33%;
    z-index: 2;

    @media (max-width: mixins.$TabletXL) {
      width: 50%;
    }

    @media (max-width: mixins.$MobileXL) {
      width: 66%;
      position: relative;
      padding-top: 7px;
      padding-bottom: 13px;
    }
  }

  &__button {
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    background: rgba(255, 255, 255, 0.6);

    @media (max-width: mixins.$TabletXL) {
      padding: 7px;
    }
  }

  &__button_active {
    padding: 15px;
    background: rgb(224, 165, 31);

    @media (max-width: mixins.$TabletXL) {
      padding: 10px;
    }
  }
}
