@use "../../../assets/styles/mixins" as mixins;
@use "../../../assets/styles/variables" as vars;

.container {
  position: relative;

  &__title {
    margin: 0;
    position: absolute;
    height: 100%;
    left: 39px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    color: vars.$globalWhite;
    text-transform: lowercase;
    @include mixins.fontSet(vars.$mainFont, vars.$fs-30);
    line-height: 1;
    z-index: 8;
  }
}

@media (max-width: vars.$Tablet) {
  .container {
    &__title {
      font-size: vars.$fs-18;
      left: 15px;
    }
  }
}

@media (max-width: vars.$Mobile) {
  .container {
    &__title {
      height: auto;
      top: 22px;
      left: 22px;
      font-size: vars.$fs-15;
      writing-mode: unset;
      transform: rotate(0);
    }
  }
}

@media (max-width: vars.$MobileS) {
  .container {
    &__title {
      font-size: vars.$fs-12;
    }
  }
}