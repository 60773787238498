@use "../../assets/styles/mixins" as mixins;
@use "../../assets/styles/variables" as vars;

.product {
  width: 100%;
  height: 1280px;
  background: url("../../assets/images/Gallery/Full/2.png") lightgray 50% / cover
    no-repeat;
  position: relative;

  &::before {
    content: "";
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(255, 255, 255, 0) 46.88%,
      rgba(0, 0, 0, 0.6) 97.92%
    );
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &__quote {
    margin: 0;
    @include mixins.fontSet(vars.$secondaryFont, vars.$fs-50);
    padding: 5.9375vw 17.708vw 3.906vw;
    @media (max-width: mixins.$Tablet) {
      padding: 15px 46px;
      font-size: 36px;
    }
    @media (max-width: mixins.$Mobile) {
      text-align: left;
      padding: 3.125vw 6.25vw;
      font-size: 35px;
    }
    @media (max-width: mixins.$MobileM) {
      font-size: 25px;
    }
  }
}
@media (max-width: vars.$Tablet) {
  .product {
    height: 597px;
  }
}

@media (max-width: vars.$Mobile) {
  .product {
    height: 681px;
    background-position: 55%;
  }
}

@media (max-width: vars.$MobileS) {
  .product {
    height: 500px;
    background-position: 61%;
  }
}
