@use "../../assets/styles/mixins" as mixins;
@use "../../assets/styles/variables" as vars;

.gallery {
  margin: 0;
  position: relative;

  &__gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      vars.$globalBlack 0%,
      rgba(0, 0, 0, 0) 30.21%,
      rgba(0, 0, 0, 0) 76.56%,
      vars.$globalBlack 100%
    );
    z-index: 1;
    pointer-events: none;

    @media (max-width: mixins.$MobileXL) {
      background: linear-gradient(
        180deg,
        vars.$globalBlack 0%,
        rgba(0, 0, 0, 0) 30.21%,
        rgba(0, 0, 0, 0) 76.56%,
        vars.$globalBlack 90%
      );
    }

    @media (max-width: mixins.$Mobile) {
      background: linear-gradient(
        180deg,
        vars.$globalBlack 0%,
        rgba(0, 0, 0, 0) 30.21%,
        rgba(0, 0, 0, 0) 76.56%,
        vars.$globalBlack 85%
      );
    }
  }
  &__sideTitle {
    display: none;
  }
}
