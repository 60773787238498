@import "../../../assets/styles/global";

.question {
  position: relative;
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  @include transition(all);


  &:hover {
    color: $globalGold;
    @include transition(color);

    .question__line {
      background-color: $globalGold;
      @include transition(background-color);
    }

    .question__line::after {
      background-color: $globalGold;
      @include transition(background-color);
    }
  }

  &:focus {
    outline: none;
  }

  &__title {
    @include fontSet($mainFont, $fs-30, $fw-400, 42.66px);
    display: flex;
    align-items: end;
    margin: 0 0 0 121px;
    padding-bottom: 6px;
    text-align: start;
    text-transform: uppercase;
    min-height: 90px;

    @media screen and (max-width: $Tablet) {
      @include fontSet($fontSize: $fs-18, $lineHeight: 25.59px);
      margin-left: 46px;
      padding-bottom: 3px;
      min-height: 51px;
    }
    @media screen and (max-width: $MobileSmall){
      @include fontSet($fontFamily: $secondaryFont, $fontSize: $fs-12, $lineHeight: 14.88px);
      max-width: 230px;
      margin-left: 20px;
      min-height: 30px;
    }
  }

  &__line {
    position: relative;
    height: 5px;
    background: $globalBlack;
    @include transition(background-color);

    @media screen and (max-width: $Tablet){
      height: 3px;
    }

    @media screen and (max-width: $MobileSmall){
      height: 2px;
    }

    &::after {
      z-index: 5;
      content: "";
      position: absolute;
      right: -1px;
      top: -7.5px;
      display: block;
      width: 20px;
      height: 20px;
      background: $globalBlack;
      border-radius: 50%;
      @include transition(background-color);

      @media screen and (max-width: $Tablet) {
        width: 17px;
        height: 17px;
        top: -7px;
      }

      @media screen and (max-width: $MobileSmall) {
        width: 11px;
        height: 11px;
        top: -4.5px;
      }
    }
    &_gold {
      background-color: $globalGold;
      @include transition(background-color);
    }
    &_gold::after {
      background-color: $globalGold;
      @include transition(background-color);
    }
  }

  &__answer {
    z-index: 6;
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100%  - 15px);
    background-color: $globalBlack;
    max-height: 0;
    overflow: hidden;
    box-sizing: border-box;
    @include transitions(max-height .2s ease, padding .2s ease, border-radius .2s ease);

    @media screen and (max-width: $Tablet){
      top: 2px;
      left: 2px;
    }

    @media screen and (max-width: $MobileSmall){
      width: calc(100%  - 10px);
    }

    &_opened {
      left: 0;
      max-height: 500px;
      padding: 84px 0;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      border-left: solid 5px $globalGold;
      border-right: solid 5px $globalGold;
      border-bottom: solid 5px $globalGold;
      @include transitions(max-height .7s ease, padding .7s ease, border-radius .7s ease);

      @media screen and (max-width: $Tablet){
        padding: 36px 0;
        border-width: 3px;
        top: 2px;
      }
      @media screen and (max-width: $Mobile){
        padding: 10px 0;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        border-width: 2px;
      }
    }

    &_stretched {
      width: 100vw;
    }

    &_text {
      @include fontSet($mainFont, $fs-30, $fw-400, 42.66px);
      padding: 0 80px;
      margin: 0;
      color: $globalWhite;
      text-align: start;
      text-transform: uppercase;

      @media screen and (max-width: $Tablet) {
        @include fontSet($fontSize: $fs-18, $lineHeight: 25.59px);
        padding: 0 46px;
      }

      @media screen and (max-width: $Mobile){
        @include fontSet($fontFamily: $secondaryFont, $fontSize: $fs-12, $lineHeight: 14.88px);
        text-transform: initial;
        padding: 0 20px;
      }
    }
  }
}
