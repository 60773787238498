@use "../../../assets/styles/mixins" as mixins;
@use "../../../assets/styles/variables" as vars;

.quoteBlock {
  margin: 0;
  display: flex;
  justify-content: center;
  @include mixins.fontSet(vars.$secondaryFont, vars.$fw-400);
  text-transform: uppercase;
  &_dark {
    background: vars.$globalBlack;
    color: vars.$globalWhite;
  }
  &_white {
    background: vars.$globalWhite;
    color: vars.$globalBlack;
  }
}
