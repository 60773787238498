@use "../../assets/styles/mixins" as m;
@use "../../assets/styles/variables" as v;

.wave {
  position: relative;

  &__container {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 9;
    pointer-events: none;
  }

  &__container_mobile {
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: v.$globalBlack;
    pointer-events: none;
  }
}