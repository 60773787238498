@import "../../../assets/styles/global";


.questions {
  list-style-type: none;
  padding: 0;
  margin: 92px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 87.5px;
  box-sizing: border-box;

  @media screen and (max-width: $Tablet) {
    margin-top: 37px;
    gap: 51px;
  }

  @media screen and (max-width: $Mobile){
    margin-top: 52px;
    gap: 36px;
  }
}
