@use "../../assets/styles/mixins" as mixins;
@use "../../assets/styles/variables" as vars;

@mixin before-shared {
  content: "";
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(255, 255, 255, 0) 46.88%,
    rgba(0, 0, 0, 0.6) 97.92%
  );
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.about {
  width: 100%;

  &__quote {
    margin: 0;
    padding: 196px 60px 183px;
    @include mixins.fontSet(vars.$secondaryFont, vars.$fs-50);
    @media (max-width: mixins.$Tablet) {
      padding: 50px 66px;
      font-size: 36px;
    }
    @media (max-width: mixins.$Mobile) {
      padding: 68px 2px;
      font-size: 25px;
    }
  }

  &__info {
    background: url("../../assets/images/Gallery/Full/1.png") lightgray 50% / cover
      no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 608px;
    position: relative;
    padding: 120px 114px 107px 120px;

    &::before {
      @include before-shared;
    }
  }

  &__text {
    margin: 0;
    color: vars.$globalWhite;
    text-align: left;
    text-transform: uppercase;
    @include mixins.fontSet(vars.$mainFont, vars.$fs-26);
    z-index: 1;

    &_type_left {
      max-width: 667px;
      align-self: flex-start;
    }

    &_type_right {
      max-width: 891px;
      align-self: flex-end;
    }
  }
}

@media (max-width: vars.$Tablet) {
  .about {
    &__head {
      padding: 50px 60px;
      font-size: vars.$fs-36;
    }

    &__info {
      gap: 102px;
      padding: 0 46px 22px;
    }

    &__text {
      font-size: vars.$fs-18;

      &_type_left {
        max-width: 440px;
      }

      &_type_right {
        max-width: 467px;
      }
    }
  }
}

@media (max-width: vars.$Mobile) {
  .about {
    &__head {
      font-size: vars.$fs-35;
      padding: 50px 10px;
    }

    &__info {
      display: block;
      background: none;
      padding: 0;

      &::before {
        display: none;
      }
    }

    &__text {
      font-size: vars.$fs-20;

      &__border {
        display: block;
        border: 3.13px solid rgb(224, 165, 31);
        border-radius: 25px;
        padding: 10px 18px;
      }

      &_type_left {
        max-width: 100%;
        position: relative;
        background: url("../../assets/images/Gallery/Full/1.png") lightgray 60% /
          cover no-repeat;
        padding: 230px 12px 20px;

        &::before {
          @include before-shared;
          z-index: -1;
        }
      }

      &_type_right {
        max-width: 100%;
        background-color: vars.$globalBlack;
        padding: 18px 12px;
      }

    }
  }
}

@media (max-width: vars.$MobileS) {
  .about {
    &__head {
      font-size: vars.$fs-25;
    }

    &__text {
      font-size: vars.$fs-12;

      &__border {
        border: none;
        padding: 0;
      }

      &_type_left {
        background: url("../../assets/images/Gallery/Full/1.png") lightgray 85% /
          637px 414px no-repeat;
        background-position-y: 0;
        padding: 70px 125px 37px 22px;
      }

      &_type_right {
        padding: 25px 22px 30px;
      }
    }
  }
}