@use "../../../assets/styles/mixins" as mixins;
@use "../../../assets/styles/variables" as vars;

.text {
  position: absolute;
  margin: 0;
  color: vars.$globalWhite;
  @include mixins.fontSet(vars.$secondaryFont, vars.$fs-30);

  &_type_build {
    width: 312px;
    text-align: left;
    left: 14%;
    top: 27%;
  }

  &_type_design {
    width: 255px;
    text-align: right;
    right: 38%;
    top: 3%
  }

  &_type_sound {
    width: 356px;
    text-align: right;
    right: 6.8%;
    top: 28.4%
  }

  &_type_absorption {
    width: 333px;
    text-align: right;
    right: 7%;
    top: 76%
  }

  &_type_components {
    width: 350px;
    text-align: left;
    left: 13%;
    top: 62%;
  }
}

@media (max-width: vars.$Tablet) {
  .text {
    font-size: vars.$fs-18;

    &_type_build {
      width: 197px;
      left: 14.5%;
      top: 39%;
    }

    &_type_design {
      width: 177px;
      text-align: left;
      left: 20%;
      top: 8.5%;
    }

    &_type_sound {
      width: 197px;
      right: 9.5%;
      top: 64.5%
    }

    &_type_absorption {
      width: 203px;
      right: 10.5%;
      top: 16%
    }

    &_type_components {
      width: 197px;
      left: 10%;
      top: 60.5%;
    }
  }
}

@media (max-width: vars.$Mobile) {
  .text {
    box-sizing: border-box;
    min-width: min-content;
    border-radius: 10px;
    border: 3px solid vars.$globalGold;
    background: vars.$globalBlack;
    font-size: vars.$fs-15;

    &_type_build {
      left: 40%;
      top: 40%;
      width: 46%;
      padding: 18px 14px;
    }

    &_type_design {
      left: 8%;
      top: 12%;
      width: 33%;
      padding: 21px 13px;
    }

    &_type_sound {
      right: 3%;
      top: 72%;
      width: 60%;
      text-align: left;
      padding: 20px 15px;
    }

    &_type_absorption {
      right: 7%;
      top: 9.5%;
      width: 62%;
      text-align: left;
      padding: 20px;
    }

    &_type_components {
      left: 12%;
      top: 78%;
      width: 69%;
      padding: 20px 14px;
    }

    &_visibility_visible {
      visibility: visible;
    }
    
    &_visibility_hidden {
      visibility: hidden;
    }
  }
}


@media (max-width: vars.$MobileS) {
  .text {
    font-size: vars.$fs-12;
    border-width: 2px;

    &_type_build {
      width: 150px;
      left: 46%;
      top: 34%;
      padding: 10px 10px 10px 12px;
    }

    &_type_design {
      width: 106px;
      padding: 14px 8px;
    }

    &_type_sound {
      width: 192px;
      right: 8%;
      padding: 5px 10px;
    }

    &_type_absorption {
      width: 200px;
      right: 9%;
      top: 19%;
      padding: 4px 14px;
    }

    &_type_components {
      top: 80%;
      padding: 9px 13px;
    }
  }  
}