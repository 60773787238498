@import "../../assets/styles/_global";

$opaqueWhite: #ffffffff; // Обязательно непрозрачный

@mixin left-right {
  &_left {
    text-align: left;
    align-items: flex-start;    
  }
  &_right {
    text-align: right;
    align-items: flex-end;
  }
}

.orderSectionArticle {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  @include left-right;

  &__title {
    margin: 0;
    padding: 0;
  
    color: $globalBlack;
    @include fontSet($secondaryFont, 30px, 700, 125.264% /* 37.579px */);
    text-transform: uppercase;

    @include left-right;
    background-color: $opaqueWhite;
  }
  
  &__text {
    margin: 0;
    padding: 5px 5px 5px 0px; // подогнано визуально под макет, если шрифт поменяется, то вероятно убрать
  
    color: $globalBlack;
    @include fontSet($mainFont, 30px, 400, 125.264% /* 37.579px */);
  
    text-wrap: balance;

    &_preLine { /* Нельзя проставить всем текстовым блокам pre-line т.к. это отключает text-wrap: balance, который необходим для блока "Индивидуальный дизайн" */
      white-space: pre-line; /* Необходим блокам, где нужно сохранить явно заданные в тексте переносы строк */
    }

    @include left-right;
    background-color: $opaqueWhite;
  }  
}

@media screen and (max-width: $Tablet) {
  .orderSectionArticle {
    margin: 0;
  
    &__title {
      font-size: 18px;
      font-weight: 600;      
    }

    &__text {
      font-size: 18px;
      font-weight: 500;
      padding: 0;
    }
  }
}

@media screen and (max-width: $Mobile) {
  // Для мобильной версии компонент заменяется на другой
}
