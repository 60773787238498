@use "../../../assets/styles/variables" as vars;

@media (max-width: vars.$Mobile) {
  .circle {
    position: absolute;
    display: block;

    & circle {
      stroke-width: 3px;
    }

    &_type_build {
      right: 19%;
      top: 52%;
    }

    &_type_design {
      left: 22%;
      top: 28%
    }

    &_type_sound {
      right: 47%;
      top: 64%
    }

    &_type_absorption {
      right: 47%;
      top: 35%
    }

    &_type_components {
      left: 28%;
      top: 72%;
    }
  }
}

@media (max-width: vars.$MobileS) {
  .circle {
    & circle {
      stroke-width: 2px;
    }

    &_type_build {
      right: 22%;
      top: 49%;
    }

    &_type_design {
      left: 12%;
      top: 28%
    }

    &_type_sound {
      right: 52%;
    }

    &_type_absorption {
      right: 57%;
    }

    &_type_components {
      left: 18%;
    }
  }
}