@import "variables";

@mixin fontSet($fontFamily: $mainFont, $fontSize: $fs-30, $fontWeight: $fw-400, $lineHeight: normal) {
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

@mixin theme($bg: $globalWhite, $color: $globalBlack) {
  color: $color;
  background-color: $bg;
}

@mixin transition($property, $duration: .2s, $timing-function: ease) {
  transition: $property $duration $timing-function;
}

@mixin transitions($transitions...) {
  transition: $transitions;
}

@mixin displaySettings($display: flex, $align: center, $justify: center) {
  display: $display;
  align-items: $align;
  justify-content: $justify;
}
