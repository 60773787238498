@import "../../assets/styles/_global";

.orderSection {
  &__sideTitle {
    color: $globalBlack;
    text-align: left;
    bottom: 151px;
    height: calc(100% - 151px);
  }

  &__basisInstallation {
    margin: 20px 160px 99px 96px;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  &__basisInstallationTitle {
    color: $globalBlack;
    text-align: left;
    @include fontSet($secondaryFont, 50px, 400, 125.264%);
    text-transform: uppercase;

    margin: 0 0 0 81px;
    padding: 0;
    align-self: flex-start;
  }

  &__list {
    list-style: none;
    margin: 99px 0 0;
    padding: 0;

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 39fr 61fr;
    align-items: flex-start;
    row-gap: 104px;
    column-gap: 0;
  }

  &__item {
    margin: 0;
    padding: 0;
  }

  &__item:last-of-type {
    padding-top: 218px;
    padding-right: 200px;
  }

  &__footer {
    height: 473px;
    width: 100%;
    background: $globalBlack;
  }

  &__formContainer {
    margin-left: -77px;
    margin-bottom: -336px;
    min-width: 100%;
    max-width: 100%;
  }
  &__quote1 {
    margin: 0;
    @include fontSet($secondaryFont, $fs-70);
    padding: 132px 138px 100px;
    @media (max-width: $Tablet) {
      padding: 46px;
      font-size: 36px;
    }
    @media (max-width: $Mobile) {
      text-align: left;
      padding: 37.5px 16.62px 25px 36.94px;
      font-size: 35px;
    }
    @media (max-width: $MobileM) {
      font-size: 25px;
    }
  }
  &__quote2 {
    margin: 0;
    @include fontSet($secondaryFont, $fs-70);
    padding: 168px 158px 163px;
    @media (max-width: $Tablet) {
      padding: 65px 0px;
      font-size: 36px;
    }
    @media (max-width: $Mobile) {
      padding: 12px 0px 14px;
      font-size: 35px;
    }
    @media (max-width: $MobileM) {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: $Tablet) {
  .orderSection {
    &__sideTitle {
      bottom: 121px;
      height: calc(100% - 121px);
    }

    &__basisInstallation {
      margin: 15px 66px 0px;
    }

    &__basisInstallationTitle {
      font-size: 36px;
      margin: 0 0 0 -20px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      margin: 27px 0 16px;
    }

    &__item:last-of-type {
      padding-top: unset;
      padding-right: unset;
    }

    &__footer {
      height: 0;
    }

    &__formContainer {
      margin-left: unset;
      margin-bottom: unset;
    }
  }
}

@media screen and (max-width: $Mobile) {
  .orderSection {
    &__sideTitle {
      visibility: hidden;
    }

    &__basisInstallation {
      margin: 15.62px 0 0;
    }

    &__basisInstallationTitle {
      font-size: 35px;
      line-height: 0.8em; // Подгоняем под дизайн
      margin: 0 0 0 31.25px;
    }

    &__formContainer {
      background-color: $globalBlack;
    }

    &__questions {
      margin-top: 27px;
      margin-bottom: 9px;
      gap: 6px;
      @include fontSet($mainFont, 15px, 400, 125.26%);
    }

    &__questionTitle {
      @include fontSet($secondaryFont, 20px, 400, 27px);
      text-transform: uppercase;
      letter-spacing: 0;
      padding-bottom: 0;
      margin-left: 30px;
    }

    &__answer {
      @include fontSet($mainFont, 15px, 400, 125.26%);
      letter-spacing: 0%;
      text-align: left;      
    }
  }
}

@media screen and (max-width: $MobileSmall) {
  .orderSection {
    &__basisInstallation {
      margin: 10px 0 0;
    }

    &__basisInstallationTitle {
      font-size: 25px;
      margin: 0 0 0 20px;
    }

    &__questions {
      margin-top: 19px;
      margin-bottom: 9px;
      gap: 16px;
      @include fontSet($mainFont, 12px, 500, 125.26%);
    }

    &__questionTitle {
      @include fontSet($secondaryFont, 12px, 400, normal);
      margin-left: 20px;
    }

    &__answer {
      @include fontSet($mainFont, 12px, 500, 125.26%);
    }
  }
}
