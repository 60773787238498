@import "../../assets/styles/_global";

$hoverOpacity: 0.7;
$transitionTime: 0.3s;

.contacts {
  margin-bottom: -154px;
  padding: 37px 100px 4px;
  flex-shrink: 0;
  background: #FFF;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 186px;

  &__header {
    margin: 0;
    padding: 0;
    color: #000;
    text-align: center;    
    @include fontSet($fontFamily: $secondaryFont, $fontSize: 30px, $fontWeight: 500, $lineHeight: normal);
  }

  &__email {
    flex-shrink: 0;

    color: #000;
    text-align: center;
    @include fontSet($fontFamily: $secondaryFont, $fontSize: 30px, $fontWeight: $fw-400, $lineHeight: 125.264%);

    text-decoration: none;
    transition: opacity $transitionTime;
  }

  &__email:hover {
    opacity: $hoverOpacity;
  }  

  
  &__links {
    margin: 0;
    padding: 29px 0;
    list-style: none;
  
    display: flex;
    flex-direction: row;
    gap: 32px;
  }
  
  &__listitem {
    display: flex;
  }
  
  &__link {
    transition: opacity $transitionTime;
    width: 81px;
    height: 81px;
  }

  &__link:hover {
    opacity: $hoverOpacity;
  }  
  
  &__linkimage {

    ///////////////////////////////////////////////
    // vertical-align: bottom; // since an image is an inline object by default, its parent element has more height to align the image with the baseline 
    // Этого оказалось недостаточно для мобильной верстки, другое решение:
    display: block;
    height: 100%;
    width: auto;      
    ///////////////////////////////////////////////
  }  
}

@media screen and (max-width: $Tablet) {
  .contacts {
    margin: 0;
    padding: 10px 46px;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    &__header {
      text-transform: lowercase;
      @include fontSet($fontFamily: $secondaryFont, $fontSize: 18px, $fontWeight: 400, $lineHeight: normal);
    }

    &__email {
      font-size: 18px;
    }

    &__links {
      margin: 0;
      padding: 0;
      gap: 17px;
    }
  
    &__link {
      width: 41px;
      height: 41px;
    }  
  }
}

@media screen and (max-width: 650px) {

  .contacts {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    row-gap: 25px;
    column-gap: 8px;

    &__headerContainer {
      grid-column: 1 / span 2;
    }

  }
}


@media screen and (max-width: $Mobile) {
  .contacts {
    padding: 9px 17px 15px;

    &__header {
      font-size: 12px;
    }

    &__email {
      font-size: 12px;
    }

    &__links {
      gap: 15px;
    }

    &__link {
      width: 30px;
      height: 30px;
    }
  }
}
