@import "variables";
@import "mixins";
@import "fonts";

body {
  min-width: 320px;
  height: 100vh;
  margin: 0;
  @include fontSet;
  @include theme;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.no_scroll {
  overflow-y: hidden;
} 