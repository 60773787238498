@import "../../../assets/styles/global";

.title{
  @include displaySettings;
  border: solid 5px $globalGold;
  border-radius: 25px;
  width: 405px;
  height: 103px;
  flex-shrink: 0;
  @include fontSet($fontSize: 25px, $lineHeight: 33px);

  @media screen and (max-width: $Tablet) {
    border-width: 3px;
    border-radius: 15px;
    width: 160px;
    height: 42px;
  }

  @media screen and (max-width: $Mobile) {
    border-width: 2px;
    border-radius: 10px;
    width: 120px;
    height: 30px;
  }


}
