@import "../../assets/styles/global";

.questionsBlock{
  padding: 298px 120px 120px 0;

  &__title{
    margin: 0 0 0 221px;
  }

  &__header{
    @include fontSet($fontFamily: $secondaryFont, $fontSize: $fs-50, $lineHeight: 62.01px)
  }

  @media screen and (max-width: $Tablet) {
    padding: 49px 54px 207px 0;

    &__title{
      margin-left: 46px;
    }

    &__header{
      @include fontSet($fontSize: $fs-36, $lineHeight: 44.65px)
    }
  }

  @media screen and (max-width: $Mobile) {
    padding: 14px 20px 130px 0;

    &__title{
      margin-left: 20px;
    }

    &__header{
      @include fontSet($fontSize: $fs-25, $lineHeight: 31.01px)
    }
  }
}
