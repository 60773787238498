@import "../../assets/styles/_global";

.contactsSection {
  padding: 0;
  background: #000;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  @media screen and (max-width: $Tablet) {
    flex-direction: column;
  }

  &__header {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    color: #FFF;

    @include fontSet($fontFamily: $mainFont, $fontSize: 30px, $fontWeight: $fw-400, $lineHeight: normal);

    margin: 0;
    padding: 0 34px 0 44px;

    @media screen and (max-width: $Tablet) {
      display: none;
    }
  }
}
