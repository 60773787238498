@use "../../assets/styles/mixins" as m;
@use "../../assets/styles/variables" as v;

.footer {
  width: 100%;
  background-color: v.$globalWhite;

  &__container {
    width: 100%;
    padding: 24px 120px 12px 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: m.$TabletXL) {
      padding: 24px 78px 12px 78px;
    }

    @media (max-width: m.$Tablet) {
      padding: 46px 56px 12px 56px;
    }

    @media (max-width: m.$Mobile) {
      padding: 14px 20px 7px 20px;
    }
  }

  &__bottom_content {
    width: 100%;
    display: flex;
    padding: 0;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }

  &__slogan {
    @include m.fontSet(v.$secondaryFont, v.$fs-128, v.$fw-400);
    width: 1550px;
    margin: 250px 0 150px 0;
    box-sizing: border-box;
    padding-right: 40px;
    color: v.$globalBlack;
    text-align: left;
    font-style: normal;
    line-height: 125%;
    position: relative;
    white-space: pre-wrap;

    // @media (max-width: m.$TabletXL) {
    //   font-size: v.$fs-85;
    //   padding-left: 60px;
    // }
    
    // @media (max-width: m.$LaptopM) {
    //   padding-left: 0px;
    //   margin-top: 100px;
    //   width: 100%;
    // }
    
    @media (max-width: m.$LaptopS) {
      margin-top: 265px;
    }

    @media (max-width: m.$LaptopM) {
      padding-left: 60px;
      margin-bottom: 78px;
      font-size: v.$fs-85;
      width: 100%;
    }

    @media (max-width: m.$Tablet) {
      margin-top: 0;
    }

    @media (max-width: m.$MobileXL) {
      margin-bottom: 59px;
      font-size: v.$fs-40;
      text-align: left;
    }
    
    &_accent {
      position: relative;
      margin: 0;
      padding: 0;
      }

    &_accent::after {
      position: absolute;
      content: "";
      left: -20px;
      right: -40px;
      top: 0px;
      bottom: -5px;
      border-radius: 50px;
      border: 5px solid v.$globalGold;

      @media (max-width: m.$LaptopM) {
        border-radius: 30px;
        left: -13px;
        right: -37px;
        top: 10px;
      }

      @media (max-width: m.$MobileXL) {
        border-radius: 15px;
        left: -5px;
        right: -16px;
        border: 2px solid v.$globalGold;
      }
    }
  }

  &__logo_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__logo_link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
    cursor: pointer;
    transition: opacity 0.3s;
  }

  &__logo_link:hover {
    opacity: 0.7;
  }

  &__logo_ico {
    width: 93px;
    height: 120px;

    //ДОБАВИЛОСЬ
    @media (max-width: m.$LaptopS) {
      width: 63px;
      height: 80px;
    }    

    @media (max-width: m.$Tablet) {
      width: 55px;
      height: 70px;
    }

    @media (max-width: m.$Mobile) {
      width: 42px;
      height: 54px;
    }
  }

  &__logo_title {
    margin: 0;
    color: #000;
    @include m.fontSet(v.$secondaryFont, v.$fs-24, v.$fw-400);
    line-height: normal;
    font-style: normal;

    //ДОБАВИЛОСЬ    
    @media (max-width: m.$LaptopS) {
      @include m.fontSet(v.$secondaryFont, v.$fs-20, v.$fw-400);
    }

    @media (max-width: m.$TabletXL) {
      @include m.fontSet(v.$secondaryFont, v.$fs-15, v.$fw-400);
    }

    @media (max-width: m.$Mobile) {
      font-size: v.$fs-12;
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    position: relative;

    &_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;

      @media (max-width: m.$Mobile) {
        position: absolute;
        align-items: center;
        right: 0;
        width: 42px;
        height: 68px;
      }
    }

    &_ico {
      width: 93px;
      height: 120px;

      //ДОБАВИЛОСЬ      
      @media (max-width: m.$LaptopS) {
        width: 63px;
        height: 80px;
      }

      @media (max-width: m.$Tablet) {
        width: 55px;
        height: 70px;
      }

      @media (max-width: m.$Mobile) {
        width: 40px;
        height: 50px;
        position: absolute;
        right: 0;
        z-index: 5;

        &_burger {
          position: relative;
        }
      }
    }

    &_text {
      display: block;
      width: 100%;
      margin-top: 6px;
      color: v.$globalBlack;
      text-align: center;
      font-size: v.$fs-30;
      font-style: normal;
      font-weight: v.$fw-400;
      line-height: 125.264%;
      transition: opacity 0.5s;

      //ДОБАВИЛОСЬ      
      @media (max-width: m.$LaptopS) {
        font-size: v.$fs-20;
      }

      @media (max-width: m.$Tablet) {
        font-size: v.$fs-15;
        margin-top: 3px;
        line-height: 18.6px;
      }

      @media (max-width: m.$Mobile) {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 52px;
        z-index: 6;
        font-size: v.$fs-12;
        line-height: normal;
        margin-top: -2px;
        margin-left: 0px;
        background: white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        mix-blend-mode: difference;
      }
      
      &_invisible {
        visibility: hidden;
      }
    }

    &_block {
      padding-right: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 40px;
      justify-content: space-between;
      overflow: hidden;
      width: 0px;
      opacity: 0;
      height: 80px;
      transition: all 0.4s;

      &_visible {
        opacity: 1;
        width: 1150px;

        @media (max-width: m.$LaptopM) {
          width:55vw;
        }

        @media (max-width: m.$TabletXL) {
          width:58vw;
        }
      }
    }

    &_link {
      @include m.fontSet(v.$secondaryFont, v.$fs-30, v.$fw-400);
      transition: opacity 0.3s;
      text-decoration: none;
      color: v.$globalBlack;
      font-style: normal;
      white-space: nowrap;

      @media (max-width: m.$LaptopM) {
        font-size: v.$fs-20;
      }
      
      @media (max-width: m.$Tablet) {
        font-size: v.$fs-15;
      }

      @media (max-width: m.$MobileL) {
        font-size: v.$fs-26;
      }
    
      &:hover {
        opacity: 0.6;
      }
    }

    &_burger {
      opacity: 0;
      width: 249px;
      height: min(100vh, 597px);
      padding-top: 10px;
      padding-bottom: 46px;
      box-sizing: border-box;
      border-radius: 50px 0px 0px 50px;
      border: 3px solid v.$globalGold;
      background-color: v.$globalWhite;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      position: fixed;
      bottom: 0;
      right: -250px;
      transition: all 0.4s;
      z-index: 10;

      @media (max-width: m.$Mobile) {
        padding-top: 14px;
        padding-bottom: 38px;
        right: -100vw;
        width: 100vw;
        height: 100%;
        top: 0;
      }

      &_button {
        width: 93px;
        height: 120px;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;

        @media (max-width: m.$Tablet) {
          width: 55px;
          height: 70px;
        }
  
        @media (max-width: m.$Mobile) {
          width: 40px;
          height: 50px;
        }
      }

      &_line {
        width: 100%;
        height: 3px;
        background-color: v.$globalGold;

        &:last-of-type {
          opacity: 0;
          margin-bottom: 30px;
        }
      }

      &_visible {
        opacity: 1;
        right: 0px;
      }
      
      &_invisible {
        display: none;
      }
    }
  }
}