/*-----------Colors------------*/

$globalGold: #e0a51f;
$globalBlack: #000;
$globalWhite: #fff;

/*-----------Fonts------------*/

$mainFont: "Montserrat", sans-serif;
$secondaryFont: "Noto Sans", sans-serif;

/*-----------FontWeights------------*/

$fw-600: 600;
$fw-400: 400;

/*-----------FontSizes------------*/

$fs-128: 128px;
$fs-85: 85px;
$fs-80: 80px;
$fs-70: 70px;
$fs-50: 50px;
$fs-40: 40px;
$fs-36: 36px;
$fs-35: 35px;
$fs-30: 30px;
$fs-26: 26px;
$fs-25: 25px;
$fs-24: 24px;
$fs-20: 20px;
$fs-26: 26px;
$fs-18: 18px;
$fs-15: 15px;
$fs-12: 12px;

/*-----------ScreenSizes------------*/

/*-----------WindowBreakpoints------------*/

/*-------MainBreakpoints--------*/

$Laptop: 1920px;
$Tablet: 1024px;
$Mobile: 500px;
$MobileSmall: 400px;
$MobileS: 350px;

/*-------SecondaryBreakpoints--------*/

$LaptopM: 1860px; // для хедера - первое уменьшение размера меню и шрифта, чтобы влезло по ширине
$LaptopS: 1600px; // для хедера - второе уменьшение размера меню и шрифта, чтобы влезло по ширине
$TabletXL: 1300px; // для хедера - девиз -> планшетный размер, чтобы поместился
$TabletL: 1200px; // для хедера - меню -> бургер
$MobileXL: 750px; // для хедера - девиз -> мобильный размер, чтобы поместился
$MobileL: 600px; // для хедера - переключение размера шрифта ссылок на мобильную версию
$MobileM: 450px; // для квоты - секция продукт изменение размера шрифта
