@import "../../../assets/styles/global";

.goldBorderText {
  border: solid 5px $globalGold;
  border-radius: 25px;
  @media (max-width: $Tablet) {
    border-width: 3px;
    border-radius: 15px;
  }
  @media (max-width: $Mobile) {
    border-width: 2px;
    border-radius: 10px;
  }
}
